import React, { useState } from 'react';
import axios from 'axios';
import Loader from './Loader';
import { parseResponse } from '../utils/parseResponse';

const CaseInteraction = ({ caseId }) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [customQuery, setCustomQuery] = useState('');

  const handleQuery = async (query) => {
    setLoading(true);
    setResponse('');
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ai/query`, { caseId, query });
      setResponse(parseResponse(res.data.response));
    } catch (error) {
      console.error('Error querying AI:', error);
      setResponse('Error querying AI.');
    } finally {
      setLoading(false);
    }
  };

  const handleCustomQuery = async (e) => {
    e.preventDefault();
    if (customQuery.trim() === '') {
      setResponse('Please enter a question.');
      return;
    }
    handleQuery(customQuery);
    setCustomQuery('');
  };

  return (
    <div className="p-4 max-w-3xl mx-auto bg-gray-100 rounded-md shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Case Interaction</h2>
      <div className="grid gap-4 grid-cols-2 mb-4">
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
          onClick={() => handleQuery('Provide a short summary of the case')}
        >
          Short Summary
        </button>
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
          onClick={() => handleQuery('Provide a detailed summary of the case')}
        >
          Detailed Summary
        </button>
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
          onClick={() => handleQuery('List key events of the case')}
        >
          Key Events
        </button>
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
          onClick={() => handleQuery('Show notes and important points of the case')}
        >
          Notes and Points
        </button>
      </div>
      <form onSubmit={handleCustomQuery} className="mb-4">
        <label className="block font-semibold mb-2">Ask a Question</label>
        <div className="flex">
          <input
            type="text"
            value={customQuery}
            onChange={(e) => setCustomQuery(e.target.value)}
            className="border p-2 w-full rounded-l-md"
            placeholder="Enter your question here"
            required
          />
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded-r-md hover:bg-blue-600"
          >
            Ask
          </button>
        </div>
      </form>
      {loading && <Loader />}
      <div className="border p-4 bg-white shadow-md rounded-md">
        {response ? <div dangerouslySetInnerHTML={{ __html: response }} /> : 'Select an option to get the response.'}
      </div>
    </div>
  );
};

export default CaseInteraction;
