import React from 'react';
import AddCase from '../components/AddCase';

const AddCasePage = () => (
  <div className="container mx-auto p-4">
    <AddCase />
  </div>
);

export default AddCasePage;
