import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CaseDropdown = ({ onSelect }) => {
  const [cases, setCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState('');

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/cases`);
        setCases(response.data);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };

    fetchCases();
  }, []);

  const handleChange = (e) => {
    const caseId = e.target.value;
    setSelectedCase(caseId);
    onSelect(caseId);
  };

  return (
    <div className="p-4">
      <label className="block font-semibold">Select Case</label>
      <select
        value={selectedCase}
        onChange={handleChange}
        className="border p-2 w-full rounded-md"
      >
        <option value="">-- Select a Case --</option>
        {cases.map((c) => (
          <option key={c._id} value={c._id}>{c.name}</option>
        ))}
      </select>
    </div>
  );
};

export default CaseDropdown;
