// src/utils/parseResponse.js
export const parseResponse = (text) => {
  const lines = text.split('\n').filter(line => line.trim() !== '');
  let html = '';
  let currentSection = '';

  lines.forEach(line => {
    if (line.startsWith('Case Summary:')) {
      html += `<h2 class="text-2xl font-bold my-4">${line.replace('Case Summary: ', '')}</h2>`;
    } else if (line.startsWith('Court:')) {
      html += `<p class="font-semibold mt-2">${line.replace('Court: ', '<span class="font-normal">')}</span></p>`;
    } else if (line.startsWith('Citation:')) {
      html += `<p class="font-semibold mt-2">${line.replace('Citation: ', '<span class="font-normal">')}</span></p>`;
    } else if (line.startsWith('Bench:')) {
      html += `<p class="font-semibold mt-2">${line.replace('Bench: ', '<span class="font-normal">')}</span></p>`;
    } else if (line.startsWith('Facts:')) {
      currentSection = 'Facts';
      html += `<h3 class="text-xl font-semibold mt-4">Facts</h3>`;
    } else if (line.startsWith('Procedural History:')) {
      currentSection = 'Procedural History';
      html += `<h3 class="text-xl font-semibold mt-4">Procedural History</h3>`;
    } else if (line.startsWith('Issues:')) {
      currentSection = 'Issues';
      html += `<h3 class="text-xl font-semibold mt-4">Issues</h3>`;
    } else if (line.startsWith('Rulings:')) {
      currentSection = 'Rulings';
      html += `<h3 class="text-xl font-semibold mt-4">Rulings</h3>`;
    } else if (line.startsWith('Decision:')) {
      currentSection = 'Decision';
      html += `<h3 class="text-xl font-semibold mt-4">Decision</h3>`;
    } else if (currentSection && line.startsWith('-')) {
      html += `<p class="ml-4">${line}</p>`;
    } else if (currentSection === 'Issues' && line.startsWith('1.')) {
      html += `<ol class="list-decimal list-inside ml-4"><li class="mt-2">${line.slice(3)}</li>`;
    } else if (currentSection === 'Issues' && line.startsWith('2.')) {
      html += `<li class="mt-2">${line.slice(3)}</li></ol>`;
    } else if (currentSection === 'Rulings' && line.startsWith('1.')) {
      html += `<ol class="list-decimal list-inside ml-4"><li class="mt-2">${line.slice(3)}</li>`;
    } else if (currentSection === 'Rulings' && line.startsWith('2.')) {
      html += `<li class="mt-2">${line.slice(3)}</li></ol>`;
    } else {
      html += `<p>${line}</p>`;
    }
  });

  return html;
};
