import React, { useState } from 'react';
import AddCasePage from './pages/AddCasePage';
import CaseInteractionPage from './pages/CaseInteractionPage';
import GeneralQueryPage from './pages/GeneralQueryPage'; // Import the new page component

const App = () => {
  const [tab, setTab] = useState('addCase');

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-500 text-white pt-4 px-0">
        <h1 className="text-xl p-4">Legal Case Management</h1>
        <nav className="mt-2">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li className="mr-2">
              <button
                className={`inline-block p-4 ${tab === 'addCase' ? 'text-blue-600 bg-gray-100 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
                onClick={() => setTab('addCase')}
              >
                Add Case
              </button>
            </li>
            <li className="mr-2">
              <button
                className={`inline-block p-4 ${tab === 'caseInteraction' ? 'text-blue-600 bg-gray-100 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
                onClick={() => setTab('caseInteraction')}
              >
                Case Interaction
              </button>
            </li>
            <li className="mr-2">
              <button
                className={`inline-block p-4 ${tab === 'generalQuery' ? 'text-blue-600 bg-gray-100 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
                onClick={() => setTab('generalQuery')}
              >
                General Query
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <main className="p-4">
        {tab === 'addCase' && <AddCasePage />}
        {tab === 'caseInteraction' && <CaseInteractionPage />}
        {tab === 'generalQuery' && <GeneralQueryPage />}
      </main>
    </div>
  );
};

export default App;
