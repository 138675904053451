import React, { useState } from 'react';
import CaseDropdown from '../components/CaseDropdown';
import CaseInteraction from '../components/CaseInteraction';

const CaseInteractionPage = () => {
  const [selectedCase, setSelectedCase] = useState('');

  return (
    <div className="container mx-auto p-4">
      <CaseDropdown onSelect={setSelectedCase} />
      {selectedCase && <CaseInteraction caseId={selectedCase} />}
    </div>
  );
};

export default CaseInteractionPage;
